body {
  background: #eeeef4;
  color: #999;
  font-family: Roboto;
}

h1 {
  font-weight: 100;
  font-size: 3rem;
  color: #e43;
  margin-bottom: 1vw;
}

p {
  font-weight: 300;
}

.warning-content {
  position: absolute;
  top: 25%;
  width: 100%;
  height: 300px;
  text-align: center;
  margin: 0;
  margin-top: 2vw;
}