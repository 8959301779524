@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:wght@400;700&display=swap");

@font-face {
  font-family: "Adrip";
  src: local("Adrip"), url(./assets/font/adrip1.ttf);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  font-family: Comfortaa;
}

a {
  color: unset;
  text-decoration: none;
}
