.contacts-wrapper {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(57, 57, 58, 0.5)
    ),
    url(../../assets/contacts.jpg);
  height: 70vw;
  object-fit: contain;
}

.contacts-wrapper-title {
  margin: 0 auto;
  margin-top: 10vw;
  text-align: center;
  color: azure;
  font-size: 5vw;
}
.contacts-wrapper-phone {
  margin: 0 auto;
  width: 70% ;
  line-height: 7vw;
  text-align: center;
  color: azure;
  font-size: 5vw;
}

.contacts-container {
  margin: 0 auto;
  text-align: center;
  color: #f4dfb3;
  margin-top: 3vw;
}

.contacts-container h6 {
  width: 80%;
  margin: 0 auto;
  line-height: 6vw;
  margin-bottom: 1vw;
  
}

.contacts-container h3 {
  margin-bottom: 5vw;
  line-height: 7.3vw;
}

.contacts-wrapper h1 span {
  font-size: 7vw;
  color: #f4dfb3;
}

@media screen and (min-width: 768px) {
  .contacts-wrapper {
    height: 20vw;
    margin-top: -0.3rem;
  }
  .contacts-wrapper-title {
    margin-top: 4vw;
    font-size: 2vw;
  }

  .contacts-wrapper-phone {
    margin-top: 2vw;
    font-size: 2vw;
    width: 70% ;
    line-height: 2vw;
  }

  .contacts-container h3 {
    margin-bottom: 1vw;
    line-height: 0.3vw;
  }
  .contacts-container h3 span {
    font-size: 1.2vw;
    margin-right: 1vw;
  }
  .contacts-wrapper h1 span {
    font-size: 2vw;
  }
}
