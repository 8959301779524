.products-wrapper {
  margin-top: -5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(../../assets/products-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 5rem;
}

.products-wrapper-title {
  font-size: 4vw;
  color: azure;
  margin-bottom: 10vw;
  margin-top: 10vw;
}

.products-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3vw;
}

.vapes-info {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-top: 1rem;
  font-size: 3.2vw;
  color: wheat;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .products-wrapper {
    margin-top: -1vw;
    padding-bottom: 0rem;
  }
  .products-container {
    width: 60%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-direction: row;
    gap: 3vw;
    margin-bottom: 10vw;
  }

  .products-wrapper-title {
    font-size: 2vw;
    margin-bottom: 3vw;
    margin-top: 4vw;
  }

  .vapes-info {
    margin-top: 3rem;
    font-size: 1.5vw;
  }
}
