@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:wght@400;700&display=swap);
.home-wrapper {
  display: flex;
  flex-direction: column;
}

#nav-icon1 {
  width: 40px;
  height: 15px;
  position: relative;
  margin-top: 2vh;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #f4dfb3;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 12px;
}

#nav-icon1 span:nth-child(3) {
  top: 24px;
}

#nav-icon1.open span:nth-child(1) {
  top: 12px;
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: 60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 12px;
  transform: rotate(-135deg);
}

#nav-icon1 span:-webkit-any-link {
  cursor: none;
}
#nav-icon1 span:-webkit-any-link {
  cursor: none;
}

.navbar {
  background: black;
  position: fixed;
  display: flex;
  width: 100%;
  font-size: 1.4rem;
  top: 10;
  z-index: 999;
}

.navbar-home {
  background: black;
  opacity: 0.8;
  position: fixed;
  width: 100%;
  font-size: 1.4rem;
  top: 10;
  z-index: 999;
}

.navbar-home-transparent {
  position: fixed;
  width: 100%;
  font-size: 1.4rem;
  top: 10;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-around;
  height: 80px;
  align-items: center;
}

.navbar-logo-container {
  width: 12.5vw;
  margin-top: 0vw;
}

.navbar-logo {
  justify-self: start;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.navbar-logo img {
  width: 100%;
  height: auto;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
}

.nav-item {
  height: 60px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #f4dfb3;
  cursor: pointer;
}

.nav-links {
  color: whitesmoke;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

.logo-font {
  font-size: 4rem;
  font-family: Adrip;
  color: #bd8e2f;
}

@media screen and (max-width: 940px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 68px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: flex-start;
    overflow: hidden;
    list-style-type: none;
  }
  .navbar-home {
    width: 100%;
  }

  .navbar-container {
    height: 70px;
    width: 100%;
  }

  .nav-menu.active {
    background: black;
    left: 0;
    opacity: 0.95;
    transition: all 0.6s ease;
    z-index: 1;
    border-bottom-right-radius: 10vw;
    border-bottom-left-radius: 10vw;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: white;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    height: 5vw;
    margin-bottom: 5vw;
  }

  .navbar-logo-container {
    width: 20.55vw;
    margin-top: 1vw;
  }

  .navbar-logo {
    z-index: 9999;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0.4rem;
    right: 0;
    transform: translate(-100%, 0%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--primary-color);
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }

  .save-hours {
    display: none;
  }

  .save-hours-mobile {
    display: block !important;
  }

  .save-hours-mobile {
    border: 2px solid #f4dfb3;
    padding: 1.6vw;
    transition: 0.2s ease-in-out;
    border-radius: 0.4vw;
    color: white;
    margin-bottom: 7vw;
  }

  .save-hours-mobile a {
    display: flex;
    align-items: center;
  }

  .save-hours-mobile svg {
    margin-right: 1vw;
    font-size: 5vw;
  }

  .navbar-home-transparent {
    position: fixed;
    display: flex;
    width: 100%;
    font-size: 1.4rem;
    top: 10;
    z-index: 999;
  }
}

.save-hours-mobile {
  display: none;
}

.save-hours {
  border: 2px solid #f4dfb3;
  padding: 0.2vw;
  transition: 0.2s ease-in-out;
  border-radius: 0.4vw;
  color: var(--primary-color);
}

.save-hours a {
  color: white;
  display: flex;
  align-items: center;
}

.save-hours svg {
  margin-right: 0.4vw;
  font-size: 1.4vw;
}

.save-hours:hover {
  cursor: pointer;
  box-shadow: 0px 10px 13px -7px #00000037, 0px 0px 10px 1px rgba(0, 0, 0, 0.44);
  transform: translateY(-3px);
}

@media screen and (max-width: 540px) {
  .navbar-logo-container {
    width: 35vw;
  }
}

.contacts-wrapper {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(57, 57, 58, 0.5)
    ),
    url(/static/media/contacts.a4e5f9c0.jpg);
  height: 70vw;
  object-fit: contain;
}

.contacts-wrapper-title {
  margin: 0 auto;
  margin-top: 10vw;
  text-align: center;
  color: azure;
  font-size: 5vw;
}
.contacts-wrapper-phone {
  margin: 0 auto;
  width: 70% ;
  line-height: 7vw;
  text-align: center;
  color: azure;
  font-size: 5vw;
}

.contacts-container {
  margin: 0 auto;
  text-align: center;
  color: #f4dfb3;
  margin-top: 3vw;
}

.contacts-container h6 {
  width: 80%;
  margin: 0 auto;
  line-height: 6vw;
  margin-bottom: 1vw;
  
}

.contacts-container h3 {
  margin-bottom: 5vw;
  line-height: 7.3vw;
}

.contacts-wrapper h1 span {
  font-size: 7vw;
  color: #f4dfb3;
}

@media screen and (min-width: 768px) {
  .contacts-wrapper {
    height: 20vw;
    margin-top: -0.3rem;
  }
  .contacts-wrapper-title {
    margin-top: 4vw;
    font-size: 2vw;
  }

  .contacts-wrapper-phone {
    margin-top: 2vw;
    font-size: 2vw;
    width: 70% ;
    line-height: 2vw;
  }

  .contacts-container h3 {
    margin-bottom: 1vw;
    line-height: 0.3vw;
  }
  .contacts-container h3 span {
    font-size: 1.2vw;
    margin-right: 1vw;
  }
  .contacts-wrapper h1 span {
    font-size: 2vw;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
}

.content-image-container img {
  max-width: 100%;
  height: auto;
}

.content-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -0.3rem;
  height: 20rem;
}

.content-info-container-1 {
  background-color: #bd8e2f;
}
.content-info-container-2 {
  background-color: #bd8e2f;
}

.content-info-container-title {
  width: 80%;
  text-align: center;
  margin-bottom: 2rem;
  font-family: Poppins;
  font-size: 2rem;
  line-height: 2.3rem;
}

.content-info-container-subTitle {
  width: 80%;
  text-align: center;
  line-height: 1.4rem;
}

.content-container:last-of-type {
  margin-top: -0.3rem;
}

@media screen and (min-width: 768px) {
  .content-container {
    display: flex;
    flex-direction: row;
  }
  .content-container:last-of-type {
    flex-direction: row-reverse;
  }
  .content-image-container {
    width: 50%;
  }

  .content-info-container {
    width: 50%;
    height: auto;
    margin-top: 0rem;
  }
  .content-info-container-subTitle {
    width: 60%;
    line-height: 1.4rem;
  }
  .content-info-container-title {
    font-size: 2.4rem;
    margin-bottom: 1rem;
    line-height: 2.5rem;
  }
}

.header-wrapper {
  width: 100%;
}

.header-wrapper img {
  width: 100%;
  height: 85vh;
  object-fit: cover;
}

.header-wrapper-content {
  margin: 0 auto;
  position: relative;
  margin-top: -30vw;
  margin-bottom: 10vw;
}
.header-wrapper-content h1 {
  width: 60%;
  line-height: 5vw;
  margin: 0 auto;
  font-size: 4.2vw;
  text-align: center;
  background-color: rgba(34, 34, 34, 0.651);
  padding: 2vw;
  border: 1px solid wheat;
  border-radius: 3vw;
  color: rgb(246, 242, 234);
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .header-wrapper-content {
    margin: 0 auto;
    position: relative;
    margin-top: -10vw;
    margin-bottom: 2vw;
  }
  .header-wrapper-content h1 {
    width: 45%;
    line-height: 2.8vw;
    font-size: 2.2vw;
    padding: 1vw;
    border: 1px solid wheat;
    border-radius: 1vw;
  }
}

.type-products {
  margin-top: -5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(/static/media/products-bg.74acd028.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 5rem;
}

img {
  max-width: 100%;
  height: auto;
}

.prodcuts-wrapper h3 {
  color: white;
  font-size: 1.5rem;
  margin: 1rem;
  margin-bottom: 4rem;
}

@media screen and (min-width: 768px) {
  .type-products {
    margin-top: -1vw;
    padding-bottom: 7rem;
  }
  .products-wrapper-title {
    font-size: 2vw;
    margin-bottom: 3vw;
    margin-top: 4vw;
  }

  .prodcuts-wrapper {
    display: flex;
    width: 80%;
    grid-gap: 3vw;
    gap: 3vw;
  }

  .prodcuts-wrapper h3 {
    font-size: 1.4rem;
    margin-top: 1.5rem;
  }
}

.products-wrapper {
  margin-top: -5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(/static/media/products-bg.74acd028.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 5rem;
}

.products-wrapper-title {
  font-size: 4vw;
  color: azure;
  margin-bottom: 10vw;
  margin-top: 10vw;
}

.products-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  grid-gap: 3vw;
  gap: 3vw;
}

.vapes-info {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-top: 1rem;
  font-size: 3.2vw;
  color: wheat;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .products-wrapper {
    margin-top: -1vw;
    padding-bottom: 0rem;
  }
  .products-container {
    width: 60%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-direction: row;
    grid-gap: 3vw;
    gap: 3vw;
    margin-bottom: 10vw;
  }

  .products-wrapper-title {
    font-size: 2vw;
    margin-bottom: 3vw;
    margin-top: 4vw;
  }

  .vapes-info {
    margin-top: 3rem;
    font-size: 1.5vw;
  }
}

.single-product-wrapper {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 90%;
  border: 1px solid wheat;
}

.single-product-wrpaper-content {
  margin-bottom: 4vw;
}

.single-product-wrapper h3 {
  width: 70%;
  text-align: center;
  margin: 0 auto;
  color: aliceblue;
  font-size: 3vw;
  margin-top: 5vw;
}

.single-product-wrapper h5 {
  color: aliceblue;
  text-align: center;
  font-size: 2.4vw;
  margin-top: 2vw;
}

.single-product-wrapper img {
  max-width: 100%;
  height: auto;
}

@media screen and (min-width: 768px) {
  .single-product-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .single-product-wrpaper-content {
    margin-bottom: 2vw;
  }

  .single-product-wrapper h3 {
    font-size: 1vw;
    margin-top: 1vw;
  }

  .single-product-wrapper h5 {
    font-size: 1vw;
    margin-top: 0.5vw;
  }
}

.info-wrapper {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  margin-top: 7vw;
  border: 1px solid wheat;
  padding: 1vw;
  color: wheat;
}

.animate-charcter {
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #369a25 0%,
    #DC7B46 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 4vw;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

body {
  background: #eeeef4;
  color: #999;
  font-family: Roboto;
}

h1 {
  font-weight: 100;
  font-size: 3rem;
  color: #e43;
  margin-bottom: 1vw;
}

p {
  font-weight: 300;
}

.warning-content {
  position: absolute;
  top: 25%;
  width: 100%;
  height: 300px;
  text-align: center;
  margin: 0;
  margin-top: 2vw;
}
@font-face {
  font-family: "Adrip";
  src: local("Adrip"), url(/static/media/adrip1.73119a7f.ttf);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  font-family: Comfortaa;
}

a {
  color: unset;
  text-decoration: none;
}

