.header-wrapper {
  width: 100%;
}

.header-wrapper img {
  width: 100%;
  height: 85vh;
  object-fit: cover;
}

.header-wrapper-content {
  margin: 0 auto;
  position: relative;
  margin-top: -30vw;
  margin-bottom: 10vw;
}
.header-wrapper-content h1 {
  width: 60%;
  line-height: 5vw;
  margin: 0 auto;
  font-size: 4.2vw;
  text-align: center;
  background-color: rgba(34, 34, 34, 0.651);
  padding: 2vw;
  border: 1px solid wheat;
  border-radius: 3vw;
  color: rgb(246, 242, 234);
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .header-wrapper-content {
    margin: 0 auto;
    position: relative;
    margin-top: -10vw;
    margin-bottom: 2vw;
  }
  .header-wrapper-content h1 {
    width: 45%;
    line-height: 2.8vw;
    font-size: 2.2vw;
    padding: 1vw;
    border: 1px solid wheat;
    border-radius: 1vw;
  }
}
